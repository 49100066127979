import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Grid } from '@devstart/react-bootstrap';
import { useTranslation } from 'react-i18next';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import cryptoJS from 'crypto-js';

import { userSelector } from '../redux/selectors';
import { recoveryPassword } from '../redux/actions';
import { User } from '../redux/prop-types';

import './recovery-password.css';
import InputPassword from '../components/ui/input-password';
import { isPasswordValid } from '../utils/data-complement/validate-inputs';
import { Spacer } from '../components/helpers';
import Button from '../components/ui/Button';
import { ButtonTypes } from '../components/ui/Button/button-types';

type FormValues = {
  id: string | null;
  password: string;
  codeRecovery: string;
};

type FormValidation = {
  password: string;
};

type RecoveryPasswordProps = {
  id: string | null;
  password: string;
  codeRecovery: string;
  recoveryPassword: (formValues: FormValues) => void;
};

const defaultValues = {
  id: '',
  password: '',
  codeRecovery: ''
};

const defaultValuesValidation = {
  password: ''
};

const mapStateToProps = createSelector(userSelector, (user: User) => ({
  user
}));

const mapDispatchToProps = {
  recoveryPassword
};

function RecoveryPassword(props: RecoveryPasswordProps) {
  const [formValues, setFormValues] = useState<FormValues>(defaultValues);
  const [validationForm, setValidationForm] = useState<FormValidation>(
    defaultValuesValidation
  );
  const [code, setCode] = useState('');

  const { t } = useTranslation();

  useEffect(() => {
    setCode(window.location.search.replace('?codeRecovery=', ''));
  }, [props]);

  function handlePasswordChange(e: React.FormEvent<HTMLInputElement>) {
    const value = (e.target as HTMLInputElement).value.slice(0);
    setValidationForm({ ...validationForm, password: '' });
    return setFormValues({ ...formValues, password: value });
  }

  function isEmptyField(obj: FormValues) {
    if (obj.password === '') {
      return true;
    } else {
      return false;
    }
  }

  interface DecryptedObj {
    id: string;
    codeValidation: string;
  }

  function decryptData(encryptedData: string): DecryptedObj {
    if (code) {
      const decrypted: string = cryptoJS.AES.decrypt(
        encryptedData,
        'devstartUser'
      ).toString(cryptoJS.enc.Utf8);
      return JSON.parse(decrypted) as DecryptedObj;
    } else {
      return { id: '', codeValidation: 'codigo inválido' };
    }
  }

  function handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    const { recoveryPassword } = props;
    const isEmpty = isEmptyField(formValues);
    const isValid = isPasswordValid(formValues.password);
    if (isEmpty) {
      setValidationForm({
        ...validationForm,
        password: 'Campo está vazio, favor verificar.'
      });
    } else if (!isValid) {
      setValidationForm({
        ...validationForm,
        password: 'Senha deve ter no mínimo 8 caracteres'
      });
    } else {
      const getParams = decryptData(code);
      recoveryPassword({
        ...formValues,
        id: getParams.id,
        codeRecovery: getParams.codeValidation
      });
    }
  }

  return (
    <>
      <Helmet title={`${t('recovery-password.title')} | DEVstart`} />
      <Grid>
        <Spacer size='exLarge' />
        <div className='recovery-password-container'>
          <div className='form-recovery-password-container'>
            <p id='recovery-password-title'>{t('forgot-password.title')}</p>
            <Spacer size='medium' />
            <form onSubmit={handleSubmit}>
              <InputPassword
                name='password'
                placeholder='Senha'
                value={formValues.password}
                onChange={handlePasswordChange}
                hasError={!!validationForm.password}
                messageError={validationForm.password}
              />
              <Spacer size='medium' />
              <div className='form-btn'>
                <Button
                  type='submit'
                  buttonType={ButtonTypes.Primary}
                  style={{ height: '48px', width: '100%' }}
                >
                  {t('recovery-password.buttons.update-password')}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </Grid>
    </>
  );
}

RecoveryPassword.displayName = 'RecoveryPasswordPage';

export default connect(mapStateToProps, mapDispatchToProps)(RecoveryPassword);
